import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import ApartmentsList from "../components/ApartmentsList/apartmentsList"
import ImageTextBlock from "../components/ImageTextBlock/imageTextBlock"
import Button from "../components/Button/Button"
import PageTitle from "../components/PageTitle/pageTitle"
import { useGlobalAptSelectorOpen, useGlobalCurrentHouse } from "../globalState"
import FactsList from "../components/FactsList/factsList"

const PortHuset = ({ data }) => {
  const [, setAptSelectorOpen] = useGlobalAptSelectorOpen()
  const [, setCurrentHouse] = useGlobalCurrentHouse()

  const apartmentsData = data.allSanityApartment.nodes
  const seoTitle = apartmentsData[0].apartmentType.house.seoTitle
  const seoDescription = apartmentsData[0].apartmentType.house.seoDescription

  useEffect(() => {
    setCurrentHouse("porthuset")
  }, [setCurrentHouse])

  return (
    <Layout backgroundColor="yellow">
      <Seo title={seoTitle} description={seoDescription} />
      <PageTitle title="Porthuset" number={1} />

      <ImageTextBlock
        text={[
          `Längs gatan kommer Porthusets fyra radhus om 2,5 etage att ligga. De är helt ensamma i sitt slag, med inspiration från ett så kallat Town House. Begreppet kommer ursprungligen från de engelska adelsmännens adresser inne i stan. De behövde övernatta efter dagens affärer och kvällens besök på klubben, innan de återvände till sina gods. Bakom Porthusets tidlöst vackra fasad fanns en gång den gamla smedjans kontor. Husets framsida blir nu lik den ursprungliga med tidstrogna fönster, sprutputsad fasad, gemensam port och höga takkupor i det svarta plåttaket. Fasaden mot gården knyter an till verkstadsmiljön med sin slammade tegelmur.`,
        ]}
        imagePosition="left"
        offset={1}
        columns={6}
        marginBottom="narrow"
      />

      <div style={{ position: "relative" }}>
        <StaticImage
          src="../images/bostadsvaljare.png"
          quality={95}
          layout="fullWidth"
          formats={["AUTO", "WEBP"]}
          alt="Bostadsväljare"
        />
        <div
          style={{
            position: "absolute",
            top: "calc(50% - 23px)",
            left: "calc(50% - 125px)",
          }}
        >
          <Button
            type="button"
            onClick={() => setAptSelectorOpen(true)}
            title="Öppna bostadsväljare"
          />
        </div>
      </div>
      <ImageTextBlock
        text={[
          `Kontrasten till den historiska exteriören blir snudd på dramatisk i radhusets entréplan. Här är det hela 3,2 meter i tak – känslan av massivt ljus och härlig rumsvolym är stark. Stora glaspartier i två väderstreck, ihop med en öppen och kvadratsmart planlösning, skapar en ljus och lätt atmosfär. Över 2,5 etage öppnar sig radhusets sällskapsytor, kök, entré med golvvärme, badrum, wc, två till tre sovrum och möjlighet till sovloft. Allt inramat av rena arkitekturlinjer, tidlös design och sobert exklusiva material. En stilla sommarkväll står glaspartierna öppna ut mot den egna uteplatsen. Här sitter du skyddad i grönskande gårdsmiljö, bara någon minut från stadsbruset. Porthuset är visionen om ett modernt, enkelt och urbant liv – mitt i staden.`,
        ]}
        imagePosition="right"
        offset={1}
        columns={6}
        marginBottom="narrow"
      />

      <FactsList
        facts={[
          { title: "Boarea", info: "65-73 kvm" },
          { title: "Rum", info: "3-4 rum och kök" },
          { title: "Pris", info: "6 200 000 kr - 6 650 000 kr" },
          { title: "Avgift", info: "3534-3917kr/mån" },
          { title: "Tillträde", info: "Prel. December 2024" },
          { title: "Antal lägenheter", info: "4" },
          { title: "Antal våningar / lgh", info: "2,5 plan" },
          { title: "Upplåtelseform", info: "Bostadsrätt" },
          {
            title: "Uppvärmning",
            info: "FTX med 85% \nvärmeåtervinning",
          },
          { title: "Adress", info: "Tredje Långgatan 26" },
        ]}
      />

      <ImageTextBlock
        preHeading="Byggnaden"
        text={[
          `Den befintliga byggnaden mot gatan som tidigare fungerade som kontorsdel åt den gamla smedjan hanteras varsamt och gatufasaden restaureras i samarbete med byggnadsantikvarie för att så långt som möjligt närma sig det ursprungliga utseendet. Då byggnaden uppfördes som en kulissfasad så har det aldrig funnits en ursprunglig fasad mot gårdsrummet. Gårdsfasaden tar istället sin utgångspunkt i den fasad som en gång beklädde själva verkstadsbyggnaden som stod på innergården, med småspröjsade fönster i mörka kulörer och en slammad tegelmur med pilastrar och kornisch.`,
        ]}
        imagePosition="left"
        offset={1}
        columns={5}
        image={
          <StaticImage
            src="../images/porthuset-fasad.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Porthuset fasad"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />
      <ImageTextBlock
        text={[
          `Byggnaden omprogrammeras till att rymma fyra mindre stadsradhus och tillskapar således en unik typologi i Linnéstadens stadslandskap. Alla radhus har en egen uteplats mot innergården och tillhörande sociala ytor på bottenvåningen. I bostadens mörka kärna placeras badrummet, som nås från den neutrala passagen mellan vardagsrum och kök. En trappa från vardagsrummet leder upp till ett halvplan med en extra WC och vidare upp till de privata rummen på övre våningen. Ovan halvplanets WC ligger ett litet loft som ger ett extra utrymme på den övre våningen. Sammantaget är bostadens utrymmen fördelade över två hela våningsplan och två loft/halvvåningar som ger rumskonfigurationen en spännande dynamik och smarta extrautrymmen.`,
        ]}
        imagePosition="right"
        offset={2}
        columns={4}
        image={
          <StaticImage
            src="../images/porthuset-interior.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Townhouse"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <ImageTextBlock
        preHeading="Platsen"
        text={[
          `Tredje Långgatan kännetecknas av en brokig kvarterstadsbebyggelse som varierar stort mellan höga och låga byggnadsvolymer. Rester av småskalig industribebyggelse samsas med robusta tegelbyggnader och putsfasader, från årtiondena kring förra sekelskiftet. Byggnaderna möter gaturummet med grovhuggna naturstenssocklar eller rusticerade entrévåningar. Gårdsfasaderna präglas av putsfasader i ljusa kulörer med mörkare fönsterpartier.`,
          `På fastigheten låg ursprungligen en smidesverkstad som uppfördes 1902. Bebyggelsen utgjordes av själva verkstadsbyggnaden mitt på gården och en representativ kulissfasad mot gatan. Dessa två byggnader knöts ihop av ett glastak som täckte själva gårdsytan vilken var en del av verkstaden.`,
        ]}
        imagePosition="left"
        offset={1}
        columns={5}
        image={
          <StaticImage
            src="../images/omrade.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Området"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <ImageTextBlock
        preHeading="Gården"
        text={[
          `På gårdsmarken anordnas privata uteplatser åt lägenheterna i bottenvåningen. Gårdsytan gestaltas som ett mindre privat kvarterstorg. Markbeläggningen består av tumlad betongsten och planteringen i mitten tillåts få höjd och volym som på ett naturligt sätt skapar intima rum för de privata uteplatserna.`,
          `Gårdshuset och porthuset har sina egna tydliga gestalter men samspelar tydligt i kulörsättning och proportioner.`,
        ]}
        imagePosition="right"
        offset={2}
        columns={4}
        image={
          <StaticImage
            src="../images/porthuset-terrass.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Terrassen"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <ApartmentsList data={apartmentsData} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityApartment(
      filter: {
        apartmentType: { house: { slug: { current: { eq: "porthuset" } } } }
      }
    ) {
      nodes {
        slug {
          current
        }
        _id
        area
        floor
        price
        aptId
        aptNumber
        status
        hireCharge
        title
        planArrangement {
          asset {
            gatsbyImageData
          }
        }
        apartmentType {
          type
          heroImage {
            asset {
              url
            }
          }
          house {
            seoTitle
            seoDescription
          }
        }
      }
    }
  }
`

export default PortHuset
